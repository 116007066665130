import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import './CV.scss';

class CV extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.cv.heading;
        const subheading = this.props.data.cv.subheading;
        const cvItems = this.props.data.cv.employment.map(function (employment, index) {
            return (
                <Row key={index} className="employment">
                    <Col xs={12} md={6} className="col employer">
                        <h3>{employment.employer}</h3>
                        <p>{employment.description}</p>
                        <span>{employment.duration} &bull; {employment.location}</span>
                    </Col>
                    <Col xs={12} md={6} className="col role">
                        {employment.roles.map((role, index) => {
                            return (
                                <div key={index} className="content-wrapper">
                                    <h3>{role.title}</h3>
                                    <ul>
                                        {role.responsibilities.map((responsibility, index) => {
                                            return (
                                                <li key={index}>
                                                    <span>{responsibility.text}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            );
        });
        const strapline = this.props.data.cv.strapline;
        const cta = this.props.data.cv.cta;
        const link = this.props.data.cv.link;

        return (
            <div id="CV" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col heading">
                            <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                                <h2>{heading}</h2>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="col section-subheading">
                            <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={500}>
                                <h3>{subheading}</h3>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <AnimationOnScroll animateIn={'animate__fadeIn'}>
                        {cvItems}
                    </AnimationOnScroll>
                    <Row>
                        <Col xs={12} className="col cta">
                            <div className="content-wrapper">
                                <h4>{strapline}</h4>
                                <AnimationOnScroll initiallyVisible={true} animateIn={'animate__headShake'} delay={1000}>
                                    <Button href={link} variant="mf-white" onClick={() => {
                                        gaEvent('event', 'CV CTA Click', 'link click');
                                        }}>
                                        {cta}
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </Button>
                                </AnimationOnScroll>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default CV;