import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import './Hero.scss';

class Hero extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.hero.heading;
        const cta = this.props.data.hero.cta;
        const link = this.props.data.hero.link;
        const subheadings = this.props.data.hero.subheadings.map(function (subheading, index) {
            return (
                <SwiperSlide key={index}>
                    <span className="subheading">{subheading.text}</span>
                </SwiperSlide>
            );
        });

        return (
            <div id="Hero" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col hero">
                            <AnimationOnScroll animatePreScroll={true} initiallyVisible={true} animateIn={'animate__pulse'}>
                                <h1 className="heading">
                                    {heading}
                                </h1>
                            </AnimationOnScroll>
                            <Swiper
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                                >
                                {subheadings}
                            </Swiper>
                            <AnimationOnScroll initiallyVisible={true} animateIn={'animate__headShake'} delay={1000}>
                                <Button id="hero-cta" href={link} variant="mf-outline-primary" onClick={() => {
                                    gaEvent('event', 'Hero CTA Click', 'link click');
                                    }}>
                                    {cta}
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </Button>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                </Container>
                <div className="bars">
                    <span className="bar bar1"></span>
                    <span className="bar bar2"></span>
                    <span className="bar bar3"></span>
                    <span className="bar bar4"></span>
                    <span className="bar bar5"></span>
                </div>
            </div>
        )
    }
}

export default Hero;