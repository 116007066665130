import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Frameworks.scss';
import wordpressLogo from '../../assets/images/logo-wordpress.png';
import shopifyLogo from '../../assets/images/logo-shopify.png';
import reactLogo from '../../assets/images/logo-react.png';
import laravelLogo from '../../assets/images/logo-laravel.png';
import drupalLogo from '../../assets/images/logo-drupal.png';
import magentoLogo from '../../assets/images/logo-magento.png';

class Frameworks extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.frameworks.heading;
        const images = [
            {
                image: wordpressLogo
            },
            {
                image: shopifyLogo
            },
            {
                image: reactLogo
            },
            {
                image: laravelLogo
            },
            {
                image: drupalLogo
            },
            {
                image: magentoLogo
            }
        ];
        const logos = images.map(function (images, index) {
            var delay = index * 100;
            return (
                <Col key={index} xs={6} sm={6} md={4} lg={2} className="col logo">
                    <AnimationOnScroll animateIn={'animate__fadeInUp'} delay={delay}>
                        <img src={images.image} alt="" />
                    </AnimationOnScroll>
                </Col>
            );
        });

        return (
            <div id="Frameworks" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col heading">
                            <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                                <h2>{heading}</h2>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row className="logos">
                        {logos}
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Frameworks;