import ReactGA from "react-ga4";

const gaEvent = function(action, label, category) {

    const host = window.location.hostname;
    const blacklist = ['localhost','staging.markfallaize.com'];

    if (!blacklist.includes(host)) {
        ReactGA.event({
            action: action,
            label: label,
            category: category
        });
    }
}
    
export default gaEvent;