import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import './About.scss';
import profile from '../../assets/images/profile.jpg'; 


class About extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.about.heading;
        const subheading = this.props.data.about.subheading;
        const alt = this.props.data.about.alt;
        const subsections = this.props.data.about.subsections.map(function (subsection, index) {
            return (
                <div key={index} className="subsection">
                    <h3>{subsection.subheading}</h3>
                    <p>{subsection.text}</p>
                </div>
            );
        });
        const strapline = this.props.data.about.strapline;
        const cta = this.props.data.about.cta;
        const link = this.props.data.about.link;

        return (
            <div id="About" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col heading">
                            <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                                <h2>{heading}</h2>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="col section-subheading">
                            <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={500}>
                                <h3>{subheading}</h3>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <AnimationOnScroll animateIn={'animate__fadeIn'}>
                        <Row className="about">
                            <Col xs={12} md={6} className="col image">
                                <AnimationOnScroll initiallyVisible={true} animateIn={'animate__tada'} delay={1500}>
                                    <img src={profile} alt={alt} />
                                </AnimationOnScroll>
                            </Col>
                            <Col xs={12} md={6} className="col content">
                                <div className="content-wrapper">
                                    {subsections}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="col cta">
                                <div className="content-wrapper">
                                    <h4>{strapline}</h4>
                                    <AnimationOnScroll initiallyVisible={true} animateIn={'animate__headShake'} delay={1000}>
                                        <Button href={link} variant="mf-outline-white" onClick={() => {
                                            gaEvent('event', 'About CTA Click', 'link click');
                                            }}>
                                            {cta}
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </Button>
                                    </AnimationOnScroll>
                                </div>
                            </Col>
                        </Row>
                    </AnimationOnScroll>
                </Container>
            </div>
        )
    }
}

export default About;