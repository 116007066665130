import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import './More.scss';
import profile from '../../assets/images/profile.jpg';
import linkedInLogo from '../../assets/images/logo-linkedin.png';
import awsLogo from '../../assets/images/logo-aws.png';
import reactLogo from '../../assets/images/logo-react.png';
import bitbucketLogo from '../../assets/images/logo-bitbucket.png';
import sassLogo from '../../assets/images/logo-sass.png';
import bootstrapLogo from '../../assets/images/logo-bootstrap.png';
import webpackLogo from '../../assets/images/logo-webpack.png';

class More extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.more.heading;
        const socialHeading = this.props.data.more.social.heading;
        const linkedInUrl = this.props.data.more.social.link;
        const linkedInCta = this.props.data.more.social.cta;
        const linkedInName = this.props.data.more.social.name;
        const linkedInRole = this.props.data.more.social.role;
        const linkedInStrapline = this.props.data.more.social.strapline;
        const builtWithHeading = this.props.data.more.builtwith.heading;
        const builtWithContent = this.props.data.more.builtwith.content;

        return (
            <div id="More" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col heading">
                            <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                                <h2>{heading}</h2>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="col social">
                            <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={500}>
                                <h3>{socialHeading}</h3>
                            </AnimationOnScroll>
                            <div className="linkedin-badge">
                                <div className="profile-image">
                                    <div className="top"></div>
                                    <div className="bottom"></div>
                                    <img src={profile} alt={''} />
                                </div>
                                <div className="profile-info">
                                    <div className="content-wrapper">
                                        <h4>{linkedInName}</h4>
                                        <h5>{linkedInRole}</h5>
                                        <p>{linkedInStrapline}</p>
                                        <AnimationOnScroll initiallyVisible={true} animateIn={'animate__headShake'} delay={1000}>
                                            <Button href={linkedInUrl} variant="mf-outline-custom" target="_blank" onClick={() => {
                                                gaEvent('event', 'LinkedIn CTA Click', 'link click');
                                                }}>
                                                {linkedInCta}
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Button>
                                        </AnimationOnScroll>
                                        <img src={linkedInLogo} alt={'LinkedIn'} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="col builtwith">
                            <div className="content">
                                <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={1000}>
                                    <h3>{builtWithHeading}</h3>
                                </AnimationOnScroll>
                                <p>{builtWithContent}</p>
                            </div>
                            <Row className="logos">
                                <Col xs={6} className="logo">
                                    <img src={reactLogo} alt={'React'} />
                                </Col>
                                <Col xs={6} className="logo">
                                    <img src={webpackLogo} alt={'Webpack'} />
                                </Col>
                                <Col xs={6} className="logo">
                                    <img src={bootstrapLogo} alt={'Bootstrap'} />
                                </Col>
                                <Col xs={6} className="logo">
                                    <img src={sassLogo} alt={'Sass'} />
                                </Col>
                                <Col xs={6} className="logo">
                                    <img src={awsLogo} alt={'AWS'} />
                                </Col>
                                <Col xs={6} className="logo">
                                    <img src={bitbucketLogo} alt={'Bitbucket'} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default More;