import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ScrollspyNav from "react-scrollspy-nav";
import gaEvent from "../../assets/js/tracking";
import './Header.scss';

class Header extends Component {

    state = {
        scrolled: true,
    };

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const scrolled = window.scrollY < 60;
            if (scrolled !== this.state.scrolled) {
                this.setState({ scrolled })
            }
        });
    }

    render() {
        if (!this.props.data) return null;

        const navItems = this.props.data.nav.map(function (nav, index) {
            return (
                <Nav.Link key={index} href={nav.url} onClick={() => {
                        gaEvent('event', 'Nav Link Click - ' + nav.text, 'link click');
                    }}>
                    <span>{nav.text}</span>
                    <svg className="nav-link__wave" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                    </svg>
                </Nav.Link>
            );
        });

        return (
            <Navbar id="Header" className={this.state.scrolled ? 'default' : 'scrolled'} collapseOnSelect expand="lg" variant="light" sticky="top">
                <Container>
                    <Navbar.Brand href="#Hero" onClick={() => {
                            gaEvent('event', 'Header Brand Click', 'link click');
                        }}>
                        markfallaize<span>.</span>com
                        <svg className="brand-link__wave" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                            <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                        </svg>
                    </Navbar.Brand>
                    <Navbar.Toggle className={this.state.scrolled ? 'navbar-light' : 'navbar-dark'} aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" bsPrefix="navbar-collapse">
                    <Nav className="ml-auto">
                        <ScrollspyNav
                            scrollTargetIds={['About', 'Frameworks', 'Work', 'CV', 'Contact', 'More']}
                            offset={0}
                            activeNavClass="is-active"
                            scrollDuration="10"
                            headerBackground="true"
                        >
                            {navItems}
                        </ScrollspyNav>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Header;