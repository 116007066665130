import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.scss';

class Footer extends Component {
    render() {
        if (!this.props.data) return null;

        const copyright = this.props.data.footer.copyright;

        return (
            <div id="Footer">
                <Container>
                    <Row>
                        <Col xs={12} className="col copyright">
                            <span>&copy; {(new Date().getFullYear())} {copyright}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer;