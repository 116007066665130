import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import "./Contact.scss";

export const Contact = (props) => {
    const [validated, setValidated] = useState(false);
    const [sendsuccess, setSuccess] = useState('');
    const [senderror, setError] = useState('');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm(
            'service_cpgg4xt',
            'template_leibykd',
            "#contact-form",
            'rlyzmUzVLr6CfB-NO'
        )
        .then(
            () => {
                setSuccess('true');
                console.log("Your message has been sent.");
                setValidated(false);
                e.target.reset();
                gaEvent('event', 'Contact Form Submit', 'form submit');
            },
            (error) => {
                setError('true');
                console.log("There was a problem sending your message.", error);
            }
        );
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            sendEmail(event);
        }

        setValidated(true);
    };

    if (!props.data) return null;

    const heading = props.data.contact.heading;
    const subheading = props.data.contact.subheading;
    const strapline = props.data.contact.strapline;
    const submit = props.data.contact.submit;

    return (
        <>
        <div id="Contact" className="section">
            <Container>
                <Row>
                    <Col xs={12} className="col heading">
                        <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                            <h2>{heading}</h2>
                        </AnimationOnScroll>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="col section-subheading">
                        <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={500}>
                            <h3>{subheading}</h3>
                        </AnimationOnScroll>
                        <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={1000}>
                            <h4>{strapline}</h4>
                        </AnimationOnScroll>
                    </Col>
                </Row>
                <AnimationOnScroll animateIn={'animate__fadeIn'}>
                    <Row className="form-wrapper">
                        <Col xs={12} lg={6} className="col form">
                            <Form
                                noValidate
                                ref={form}
                                onSubmit={handleSubmit}
                                validated={validated}
                                sendsuccess={sendsuccess}
                                senderror={senderror}
                                id="contact-form"
                            >
                                <Form.Group as={Col} md="12" controlId="nameValidation">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Name"
                                        className="input-wrapper"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            name="from_name"
                                            size="lg"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your name.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="emailValidation">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="input-wrapper"
                                    >
                                        <Form.Control
                                            type="email"
                                            placeholder="name@example.com"
                                            name="email_address"
                                            size="lg"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="messageValidation">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Message"
                                        className="input-wrapper"
                                    >
                                        <Form.Control
                                            placeholder="Message"
                                            name="message"
                                            size="lg"
                                            required
                                            as="textarea"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a message.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <div className="subheading">
                                    <h5>Enquiry Type:</h5>
                                </div>
                                <Form.Group as={Col} md="12" className="radio-buttons">
                                    <div className="input-wrapper">
                                        <Form.Check
                                            className="radio-button"
                                            label="Employer"
                                            name="enquiry_type"
                                            value="Employer"
                                            type="radio"
                                            id="enquiry_type__employer"
                                            required
                                        />
                                        <Form.Check
                                            className="radio-button"
                                            label="Recruiter"
                                            name="enquiry_type"
                                            value="Recruiter"
                                            type="radio"
                                            id="enquiry_type__recruiter"
                                            required
                                        />
                                        <Form.Check
                                            className="radio-button"
                                            label="Other"
                                            name="enquiry_type"
                                            value="Other"
                                            type="radio"
                                            id="enquiry_type__other"
                                            feedback="Please choose your enquiry type."
                                            feedbackType="invalid"
                                            required
                                        />
                                    </div>
                                </Form.Group>

                                <div className="input-wrapper">
                                    <AnimationOnScroll initiallyVisible={true} animateIn={'animate__headShake'} delay={1000}>
                                        <Button type='submit' variant="mf-outline-secondary">
                                            {submit}
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </Button>
                                    </AnimationOnScroll>
                                </div>

                                <div className="response">
                                    <p className={`success ${sendsuccess ? "show" : ""}`}>Thank you! Your message has been sent successfully.</p>
                                    <p className={`error ${senderror ? "show" : ""}`}>Something went wrong. Unfortunately your message was unable to be sent.</p>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </AnimationOnScroll>
            </Container>
        </div>
        </>
    );
};

export default Contact;