import React, { Component } from "react";
import ReactGA from "react-ga4";
import $ from "jquery";
import './App.scss';
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import CV from "./components/CV/CV";
import Work from "./components/Work/Work";
import Frameworks from "./components/Frameworks/Frameworks";
import Contact from "./components/Contact/Contact";
import More from "./components/More/More";
import Footer from "./components/Footer/Footer";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };

        const host = window.location.hostname;
        const blacklist = ['localhost','staging.markfallaize.com'];

        if (!blacklist.includes(host)) {
            ReactGA.initialize('G-NXK98QEGJP');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
        }
    }

    getData() {
        $.ajax({
            url: "./data.json",
            dataType: "json",
            cache: false,
            success: function(data) {
                this.setState({ data: data });
            }.bind(this),
            error: function(xhr, status, err) {
                console.log(err);
                alert(err);
            }
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        var data = this.state.data.app;

        return (
            <div className="App">
                <Header data={data} />
                <Hero data={data} />
                <About data={data} />
                <Frameworks data={data} />
                <Work data={data} />
                <CV data={data} />
                <Contact data={data} />
                <More data={data} />
                <Footer data={data} />
            </div>
        );
    }
}

export default App;
