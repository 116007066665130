import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gaEvent from "../../assets/js/tracking";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import './Work.scss';
import nextjsAsset from '../../assets/images/work-nextjs.jpg';
import wordpressAsset from '../../assets/images/work-wordpress.jpg';
import shopifyAsset from '../../assets/images/work-shopify.jpg';
import reactAsset from '../../assets/images/work-react.jpg';
import drupalAsset from '../../assets/images/work-drupal.jpg';
import magentoAsset from '../../assets/images/work-magento.jpg';

class Work extends Component {
    render() {
        if (!this.props.data) return null;

        const heading = this.props.data.work.heading;
        const subheading = this.props.data.work.subheading;
        const images = [
            {
                name: 'Nextjs',
                image: nextjsAsset
            },
            {
                name: 'Wordpress',
                image: wordpressAsset
            },
            {
                name: 'Shopify',
                image: shopifyAsset
            },
            {
                name: 'React',
                image: reactAsset
            },
            {
                name: 'Drupal',
                image: drupalAsset
            },
            {
                name: 'Magento',
                image: magentoAsset
            }
        ];
        const platforms = this.props.data.work.platforms.map(function (platform, index) {
            return (
                <SwiperSlide key={index}>
                    <Row className="item">
                        <Col xs={12} lg={4} className="col content">
                            <div className="content-wrap">
                                <h3>{platform.title}</h3>
                                <p>{platform.description}</p>
                                <p>{platform.summary}</p>
                                <h5>Brands: {platform.brands}</h5>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} className="col">
                            <img src={images[index].image} alt={platform.alt} />
                        </Col>
                    </Row>
                </SwiperSlide>
            );
        });

        return (
            <div id="Work" className="section">
                <Container>
                    <Row>
                        <Col xs={12} className="col heading">
                            <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn={'animate__pulse'}>
                                <h2>{heading}</h2>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="col section-subheading">
                            <AnimationOnScroll initiallyVisible={true} animateIn={'animate__pulse'} delay={500}>
                                <h3>{subheading}</h3>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="col carousel">
                            <AnimationOnScroll animateIn={'animate__fadeIn'}>
                                <Swiper
                                    modules={[Navigation, A11y]}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    navigation
                                    loop={true}
                                    onRealIndexChange={(swiper) => gaEvent('event', 'Work Slider - ' + images[swiper.realIndex].name, 'slide change')}
                                >
                                    {platforms}
                                </Swiper>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Work;